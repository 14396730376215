import React, { useReducer, useMemo } from 'react';
import { graphql } from "gatsby";

import LayoutBlog from '../components/LayoutBlog';
import Hero from '../components/sections/Hero';
import Posts from '../components/sections/Posts';
import Layout from "../components/layout";
import Seo from "../components/seo";

import { composePosts } from '../utils';

const POSTS_PER_PAGE = 6;

const postsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_MORE': {
      const pageStart = state.currentPage * POSTS_PER_PAGE;
      const pageEnd = pageStart + POSTS_PER_PAGE;
      const newPosts = state.allPosts.slice(0, pageEnd);
      return {
        ...state,
        currentPage: state.currentPage + 1,
        pagePosts: newPosts,
        hasNextPage: state.allPosts.length > (state.currentPage + 1) * POSTS_PER_PAGE,
      };
    }
    case 'SET_CURRENT_PAGE': {
      const requestedPage = action.payload;
      if (state.allPosts.length < requestedPage * POSTS_PER_PAGE) {
        return {
          ...state,
          currentPage: 1,
          pagePosts: state.allPosts.slice(0, POSTS_PER_PAGE),
          hasNextPage: state.allPosts.length > 1 * POSTS_PER_PAGE,
        };
      }

      const pageStart = state.currentPage * POSTS_PER_PAGE;
      const pageEnd = pageStart + POSTS_PER_PAGE;
      const newPosts = state.allPosts.slice(0, pageEnd);

      return {
        ...state,
        currentPage: requestedPage,
        pagePosts: newPosts,
        hasNextPage: state.allPosts.length > (requestedPage + 1) * POSTS_PER_PAGE,
      };
    }
    default:
      return state;
  }
}

const Post = (props) => {  
  const { data: { markdownRemark: author, allMarkdownRemark: authorPosts } } = props;
  
  const { frontmatter } = author;
  const allPosts = useMemo(() => composePosts(authorPosts.nodes), [authorPosts]);
  const [postsState, dispatchPosts] = useReducer(postsReducer, {
    allPosts,
    pagePosts: allPosts.slice(0, POSTS_PER_PAGE),
    currentPage: 1,
    hasNextPage: allPosts.length > POSTS_PER_PAGE,
  });

  const handleLoadMore = () => {
    dispatchPosts({ type: 'LOAD_MORE' });
  }

  const authorData = {
    name: frontmatter.name,
    role: frontmatter.role,
    bio: frontmatter.bio,
    avatar: frontmatter.avatar.childImageSharp.gatsbyImageData,
  };

  return (
    <LayoutBlog title={`${authorData.name} publications | Jetic`}>
      <Hero
        title="iPaaS Insights"
        description="Integration tutorials, tips and best practices"
      />
      <Posts
        rowPosts={false}
        author={authorData}
        posts={postsState.pagePosts}
        hasNextPage={postsState.hasNextPage}
        loadMoreHandler={handleLoadMore}
      />
    </LayoutBlog>
  );
};

const query = graphql`
  query ($id: String!, $slug: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        name
        role
        avatar {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 72)
          }
        }
        bio
      }
    }

    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {type: {in: ["blog", "product"]}, author: {frontmatter: {slug: {eq: $slug}}}}}
      limit: 1000
    ) {
      nodes {
        frontmatter {
          type
          title
          slug
          date(formatString: "MMMM DD")
          image {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          author {
            frontmatter {
              name
              avatar {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 56)
                }
              }
              slug
            }
          }
        }
        excerpt(format: PLAIN, pruneLength: 180)
      }
    }
  }
`;

export { query };

export default Post;